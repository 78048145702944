import styled from "styled-components";
import image from "../../assests/img/canada-bg-logo.png";

export const StyledLogin = styled.div`
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  width: 100vw;
  height: 100vh;

  .submit-btn {
    background-color: #a91010;
    color: #fff;
  }
`;
