import ApiService from "./ApiService";

export default class OrderService {
  static getAllFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count");

  static getUnreadMailCount = () =>
    ApiService.get("/common/get-unread-mail-count");

  static getPhonenumberList = () =>
    ApiService.get("/admin/get-contact-customer-phone-number");

  static getOrdersList = ({ order }) =>
    ApiService.get(
      `/admin/get-orders-list/${order.orderName}?page=${order.page}&limit=${order.perPage}&delay=1`
    );

  static getOrderDetailsByOrderId = (orderId) =>
    ApiService.get(`/admin/get-orders-details/${orderId}`);

  static deleteOrdersData = (deleteData) =>
    ApiService.put(`/admin/change-order-status`, deleteData);

  static sendRefundMail = (refundData) =>
    ApiService.put("/admin/move-to-complete-refund", refundData);

  static uploadVisaDocument = (data) =>
    ApiService.post("/admin/upload-visa-document", data);

  static resendMail = (data) => ApiService.post("/admin/resend-mail", data);

  static MoveOrderStatus = (data) =>
    ApiService.put(`/admin/move-order-status`, data);

  static defencePack = (orderId) =>
    ApiService.post("/admin/defence-pack-details", orderId);

  static GetDefencePackPdf = (data) =>
    ApiService.post("/admin/get-defence-pack-pdf", data, {
      responseType: "blob",
    });

  static printOrderInfo = (orderId) =>
    ApiService.post("/admin/print-order-info", orderId);

  static viewProcessOrder = (tmId) =>
    ApiService.get(`/admin/view-process-order/${tmId}`);

  static trackCustomerOrder = (orderData) =>
    ApiService.post(`/front/track-order`, orderData);

  static getDownloadHistory = (orderId) =>
    ApiService.post(`/front/get-download-history`, orderId);

  static searchOrder = (data) => ApiService.post(`/admin/search-order`, data);
}
