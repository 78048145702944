import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import { FaDownload } from "react-icons/fa";
import rightArrow from "../../assests/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetailsByOrderId,
  resendMail,
  uploadVisaDocument,
} from "../../redux/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";
import { fileExists } from "../../utility/utility";
import TransactionModal from "./TransactionModal/TransactionModal";
import DefenceContent from "./DefenceContent/index";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  // const [emailId, setEmailId] = useState(OrderDetails[0]?.Email);
  // const [orderid, setEmailIdOrderid] = useState(OrderDetails[0]?.order_id);

  const [emailId, setEmailId] = useState(OrderDetails?.email);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);

  const [showDefence, setShowDefence] = useState(false);
  const [showTransaction, SetShowTransaction] = useState(false);
  const [showDefencePack, setShowDefencePack] = useState(false);

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
    }

    document.title = "Orders Details | ETA Canada";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email);
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const checkFileExist = (url) => {
    if (typeof url !== "undefined" && url !== null && url !== "") {
      return fileExists(url).then((response) => {
        // console.log(typeof response, response, "response");
        let returnResponse = response || response == "true" ? true : false;
        return returnResponse;
      });
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      // console.log(values);
      const data = new FormData();
      data.append("orderId", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      dispatch(uploadVisaDocument(data))
        .unwrap()
        .then((res) => {
          resetForm();
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrderDetailsByOrderId(orderId));
          }
        });
    },
  });

  const handleResendMail = (e) => {
    e.preventDefault();
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
    };
    // console.log("data", data);
    dispatch(resendMail(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "35%" }}>Download</th>
                      <th style={{ width: "10%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? checkFileExist(
                                        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                      )
                                      ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                      : `https://canada-eta.online/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={handleResendMail}
                    >
                      Resend Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>PDF</td>
                      <tr>
                        <td>
                          <label className="file-upload">
                            <input
                              type="file"
                              className=""
                              id="fileUpload"
                              name="fileUpload"
                              accept=".pdf,image/*"
                              onChange={(e) =>
                                setFieldValue(
                                  "fileUpload",
                                  e.currentTarget.files[0]
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <span>Choose File</span>
                          </label>
                          <span className="slcted-file"></span>
                          <p>{errors.fileUpload}</p>
                        </td>
                      </tr>
                    </tr>
                    <td>
                      {OrderDetails?.uploadDoc?.length !== 0 && (
                        <a
                          href={
                            OrderDetails?.uploadDoc !== undefined
                              ? checkFileExist(
                                  `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                )
                                ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                : `https://canada-eta.online/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                              : null
                          }
                          className="blue-btn"
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Download File
                        </a>
                      )}
                    </td>
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                >
                  Submit{" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}

                  {OrderDetails?.applying_for_someone && (
                    <tr>
                      <td>Are you applying on behalf of someone?</td>
                      <td>{OrderDetails?.applying_for_someone}</td>
                    </tr>
                  )}

                  {OrderDetails?.applying_for_minor && (
                    <tr>
                      <td>Are you applying on behalf of a minor child? </td>
                      <td>{OrderDetails?.applying_for_minor}</td>
                    </tr>
                  )}

                  {OrderDetails?.representative_is && (
                    <tr>
                      <td>I am</td>
                      <td>{OrderDetails?.representative_is}</td>
                    </tr>
                  )}

                  {OrderDetails?.being_paid && (
                    <tr>
                      <td>
                        Are you being paid to represent the applicant and
                        complete the form on their behalf?
                      </td>
                      <td>{OrderDetails?.being_paid}</td>
                    </tr>
                  )}

                  {OrderDetails?.membership_id && (
                    <tr>
                      <td>Membership ID number</td>
                      <td>{OrderDetails?.membership_id}</td>
                    </tr>
                  )}

                  {OrderDetails?.province_or_territory && (
                    <tr>
                      <td>Which province or territory?</td>
                      <td>{OrderDetails?.province_or_territory}</td>
                    </tr>
                  )}

                  {OrderDetails?.surname && (
                    <tr>
                      <td>Surname(s) / last name(s)</td>
                      <td>{OrderDetails?.surname}</td>
                    </tr>
                  )}

                  {OrderDetails?.first_name && (
                    <tr>
                      <td>Given name(s) / first name(s)</td>
                      <td>{OrderDetails?.first_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.firm_or_organization && (
                    <tr>
                      <td>Name of firm, organization</td>
                      <td>{OrderDetails?.firm_or_organization}</td>
                    </tr>
                  )}

                  {OrderDetails?.mailing_address && (
                    <tr>
                      <td>Mailing address</td>
                      <td>{OrderDetails?.mailing_address}</td>
                    </tr>
                  )}

                  {OrderDetails?.telephone_number && (
                    <tr>
                      <td>Telephone Number</td>
                      <td>{OrderDetails?.telephone_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.postal_code && (
                    <tr>
                      <td>Postal code</td>
                      <td>{OrderDetails?.postal_code}</td>
                    </tr>
                  )}

                  {OrderDetails?.fax_number && (
                    <tr>
                      <td>Fax number</td>
                      <td>{OrderDetails?.fax_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.email_address && (
                    <tr>
                      <td>Email address</td>
                      <td>{OrderDetails?.email_address}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Travel Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.travel_document && (
                    <tr>
                      <td>
                        What travel document do you plan to use to travel to
                        Canada?
                      </td>
                      <td>{OrderDetails?.travel_document}</td>
                    </tr>
                  )}

                  {OrderDetails?.country_on_passport && (
                    <tr>
                      <td>
                        Select the code that matches the one on your passport.
                      </td>
                      <td>{OrderDetails?.country_on_passport}</td>
                    </tr>
                  )}

                  {OrderDetails?.lawful_permanent_resident_of_us && (
                    <tr>
                      <td>
                        Are you a lawful permanent resident of the United States
                        with a valid U.S. Citizenship and Immigration Services
                        (USCIS) number?
                      </td>
                      <td>{OrderDetails?.lawful_permanent_resident_of_us}</td>
                    </tr>
                  )}

                  {OrderDetails?.uscis_number && (
                    <tr>
                      <td>
                        United States lawful permanent resident USCIS number
                      </td>
                      <td>{OrderDetails?.uscis_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.confirm_uscis_number && (
                    <tr>
                      <td>
                        United States lawful permanent resident alien
                        registration card (Green Card) number (re-enter)
                      </td>
                      <td>{OrderDetails?.confirm_uscis_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.uscis_exp_year && (
                    <tr>
                      <td>Date of expiry</td>
                      <td>{OrderDetails?.uscis_exp_year}</td>
                    </tr>
                  )}

                  {OrderDetails?.citizen_country && (
                    <tr>
                      <td>What is the nationality noted on this passport?</td>
                      <td>{OrderDetails?.citizen_country}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Passport details of applicant</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.passport_number && (
                    <tr>
                      <td>Passport number</td>
                      <td>{OrderDetails?.passport_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.confirm_passport_numbe && (
                    <tr>
                      <td>Passport number (re-enter)</td>
                      <td>{OrderDetails?.confirm_passport_numbe}</td>
                    </tr>
                  )}

                  {OrderDetails?.passport_surname && (
                    <tr>
                      <td>Surname(s) / last name(s)</td>
                      <td>{OrderDetails?.passport_surname}</td>
                    </tr>
                  )}

                  {OrderDetails?.passport_first_name && (
                    <tr>
                      <td>Given name(s) / first name(s)</td>
                      <td>{OrderDetails?.passport_first_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.birth_year && (
                    <tr>
                      <td>Date of Birth</td>
                      <td>{OrderDetails?.birth_year}</td>
                    </tr>
                  )}

                  {OrderDetails?.gender && (
                    <tr>
                      <td>Gender</td>
                      <td>{OrderDetails?.gender}</td>
                    </tr>
                  )}

                  {OrderDetails?.country_of_birth && (
                    <tr>
                      <td>Country/territory of birth</td>
                      <td>{OrderDetails?.country_of_birth}</td>
                    </tr>
                  )}

                  {OrderDetails?.city_of_birth && (
                    <tr>
                      <td>City/town of birth</td>
                      <td>{OrderDetails?.city_of_birth}</td>
                    </tr>
                  )}

                  {OrderDetails?.passport_issue_year && (
                    <tr>
                      <td>Passport Issue Date</td>
                      <td>{OrderDetails?.passport_issue_year}</td>
                    </tr>
                  )}

                  {OrderDetails?.passport_exp_year && (
                    <tr>
                      <td>Passport Expiry Date</td>
                      <td>{OrderDetails?.passport_exp_year}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Personal Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.country && (
                    <tr>
                      <td>Country/territory</td>
                      <td>{OrderDetails?.country}</td>
                    </tr>
                  )}

                  {OrderDetails?.marital_status && (
                    <tr>
                      <td>Marital status</td>
                      <td>{OrderDetails?.marital_status}</td>
                    </tr>
                  )}

                  {OrderDetails?.appliedd_for_visa_before && (
                    <tr>
                      <td>
                        Have you ever applied for or obtained a visa, an eTA or
                        a permit to visit, live, work or study in Canada?
                      </td>
                      <td>{OrderDetails?.appliedd_for_visa_before}</td>
                    </tr>
                  )}

                  {OrderDetails?.uci_number && (
                    <tr>
                      <td>
                        Unique client identifier (UCI) / Previous Canadian visa,
                        eTA or permit number
                      </td>
                      <td>{OrderDetails?.uci_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.confirm_uci_number && (
                    <tr>
                      <td>
                        Unique client identifier (UCI) / Previous Canadian visa,
                        eTA or permit number (re-enter)
                      </td>
                      <td>{OrderDetails?.confirm_uci_number}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Employment information</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.occupation && (
                    <tr>
                      <td>Occupation</td>
                      <td>{OrderDetails?.occupation}</td>
                    </tr>
                  )}

                  {OrderDetails?.job_title && (
                    <tr>
                      <td>Job title</td>
                      <td>{OrderDetails?.job_title}</td>
                    </tr>
                  )}

                  {OrderDetails?.employer_or_school_name && (
                    <tr>
                      <td>Name of employer or school, as appropriate.</td>
                      <td>{OrderDetails?.employer_or_school_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.job_country && (
                    <tr>
                      <td>Country/territory</td>
                      <td>{OrderDetails?.job_country}</td>
                    </tr>
                  )}

                  {OrderDetails?.job_state && (
                    <tr>
                      <td>Province/state</td>
                      <td>{OrderDetails?.job_state}</td>
                    </tr>
                  )}

                  {OrderDetails?.job_city && (
                    <tr>
                      <td>City/town</td>
                      <td>{OrderDetails?.job_city}</td>
                    </tr>
                  )}

                  {OrderDetails?.working_since && (
                    <tr>
                      <td>Since what year?</td>
                      <td>{OrderDetails?.working_since}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Contact information</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.preferred_language && (
                    <tr>
                      <td>Preferred language to contact you</td>
                      <td>{OrderDetails?.preferred_language}</td>
                    </tr>
                  )}

                  {OrderDetails?.email && (
                    <tr>
                      <td>Email address</td>
                      <td>{OrderDetails?.email}</td>
                    </tr>
                  )}

                  {OrderDetails?.confirm_email && (
                    <tr>
                      <td>Email address (re-enter)</td>
                      <td>{OrderDetails?.confirm_email}</td>
                    </tr>
                  )}

                  {OrderDetails?.country_code && (
                    <tr>
                      <td>Country Code</td>
                      <td>{OrderDetails?.country_code}</td>
                    </tr>
                  )}

                  {OrderDetails?.telephone_number && (
                    <tr>
                      <td>Telephone Number</td>
                      <td>{OrderDetails?.telephone_number}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Residential address</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.apartment_number && (
                    <tr>
                      <td>Apartment/unit number</td>
                      <td>{OrderDetails?.apartment_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.house_name && (
                    <tr>
                      <td>Street/civic number or house name</td>
                      <td>{OrderDetails?.house_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.street_name && (
                    <tr>
                      <td>Street address/name</td>
                      <td>{OrderDetails?.street_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.street_name_2 && (
                    <tr>
                      <td>Street address/name line 2</td>
                      <td>{OrderDetails?.street_name_2}</td>
                    </tr>
                  )}

                  {OrderDetails?.city && (
                    <tr>
                      <td>City/town</td>
                      <td>{OrderDetails?.city}</td>
                    </tr>
                  )}

                  {OrderDetails?.country && (
                    <tr>
                      <td>Country/territory of birth</td>
                      <td>{OrderDetails?.country}</td>
                    </tr>
                  )}

                  {OrderDetails?.res_state && (
                    <tr>
                      <td>Province/state</td>
                      <td>{OrderDetails?.res_state}</td>
                    </tr>
                  )}

                  {OrderDetails?.res_zip_code && (
                    <tr>
                      <td>ZIP Code / Postal</td>
                      <td>{OrderDetails?.res_zip_code}</td>
                    </tr>
                  )}

                  {OrderDetails?.district && (
                    <tr>
                      <td>District/region</td>
                      <td>{OrderDetails?.district}</td>
                    </tr>
                  )}

                  {OrderDetails?.do_you_know_when_travel_to_canada && (
                    <tr>
                      <td>Do you know when you will travel to Canada?</td>
                      <td>{OrderDetails?.do_you_know_when_travel_to_canada}</td>
                    </tr>
                  )}

                  {OrderDetails?.travel_to_canada_year && (
                    <tr>
                      <td>When do you plan to travel to Canada?</td>
                      <td>{OrderDetails?.travel_to_canada_year}</td>
                    </tr>
                  )}

                  {OrderDetails?.travel_to_canada_hour && (
                    <tr>
                      <td>
                        Please enter the time your flight to Canada will depart
                      </td>
                      <td>{OrderDetails?.travel_to_canada_hour}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Background Questions</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.have_you_ever_been_refused_a_visa_or_permit && (
                    <tr>
                      <td>
                        Have you ever been refused a visa or permit, denied
                        entry to, or ordered to leave Canada or any other
                        country/territory?
                      </td>
                      <td>
                        {
                          OrderDetails?.have_you_ever_been_refused_a_visa_or_permit
                        }
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.country_that_refused_you_a_visa_or_permit && (
                    <tr>
                      <td>
                        For each refusal, please indicate the country that
                        refused you a visa or permit, or denied you entry, as
                        well as the reasons provided to you by the country.
                      </td>
                      <td>
                        {
                          OrderDetails?.country_that_refused_you_a_visa_or_permit
                        }
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.have_you_ever_committed_been_arrested && (
                    <tr>
                      <td>
                        Have you ever committed, been arrested for, been charged
                        with or convicted of any criminal offence in any
                        country/territory?
                      </td>
                      <td>
                        {OrderDetails?.have_you_ever_committed_been_arrested}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.please_indicate_where_committed_been_arrested && (
                    <tr>
                      <td>
                        For each arrest, charge, or conviction, please indicate
                        where (city, country), when (month/year), the nature of
                        the offence, and the sentence.
                      </td>
                      <td>
                        {
                          OrderDetails?.please_indicate_where_committed_been_arrested
                        }
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.is_your_contact_with_tuberculosis && (
                    <tr>
                      <td>
                        Is your contact with tuberculosis the result of being a
                        health care worker?
                      </td>
                      <td>{OrderDetails?.is_your_contact_with_tuberculosis}</td>
                    </tr>
                  )}

                  {OrderDetails?.have_you_ever_been_diagnosed_with_tuberculosis && (
                    <tr>
                      <td>Have you ever been diagnosed with tuberculosis?</td>
                      <td>
                        {
                          OrderDetails?.have_you_ever_been_diagnosed_with_tuberculosis
                        }
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.past_two_years_diagnosed_with_tuberculosis && (
                    <tr>
                      <td>
                        In the past two years, were you diagnosed with
                        tuberculosis or have you been in close contact with a
                        person with tuberculosis?
                      </td>
                      <td>
                        {
                          OrderDetails?.past_two_years_diagnosed_with_tuberculosis
                        }
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.have_one_of_these_conditions && (
                    <tr>
                      <td>Do you have one of these conditions?</td>
                      <td>{OrderDetails?.have_one_of_these_conditions}</td>
                    </tr>
                  )}

                  {OrderDetails?.additional_details && (
                    <tr>
                      <td>
                        Please briefly indicate if there are additional details
                        pertinent to your application
                      </td>
                      <td>{OrderDetails?.additional_details}</td>
                    </tr>
                  )}

                  {OrderDetails?.payment_status && (
                    <tr>
                      <td>Payment status</td>
                      <td>{OrderDetails?.payment_status}</td>
                    </tr>
                  )}

                  {OrderDetails?.process_status && (
                    <tr>
                      <td>Process status</td>
                      <td>{OrderDetails?.process_status}</td>
                    </tr>
                  )}

                  {OrderDetails?.assign_date && (
                    <tr>
                      <td>Assign date</td>
                      <td>{OrderDetails?.assign_date}</td>
                    </tr>
                  )}

                  {OrderDetails?.processing_type && (
                    <tr>
                      <td>Processing type</td>
                      <td>{OrderDetails?.processing_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.customer_sign && (
                    <tr>
                      <td>View Digital Signature</td>
                      <td>
                        <Link
                          to={
                            OrderDetails?.customer_sign
                              ? `https://canada-eta.online/${OrderDetails?.customer_sign}`
                              : "#"
                          }
                          target="_blank"
                        >
                          Click Here
                        </Link>
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.create_date && (
                    <tr>
                      <td>US Date</td>
                      <td>{OrderDetails?.create_date}</td>
                    </tr>
                  )}

                  {OrderDetails?.create_time && (
                    <tr>
                      <td>US EST</td>
                      <td>{OrderDetails?.create_time}</td>
                    </tr>
                  )}

                  {OrderDetails?.ip_address && (
                    <tr>
                      <td>IP address</td>
                      <td>{OrderDetails?.ip_address}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Download History</h2>
            <div className="table-block table-responsive">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order ID</th>
                    <th width="22%">Date & Time</th>
                    <th width="23%">Downloaded File</th>
                    <th width="20%">IP Address</th>
                    <th width="20%">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.downloadHistory ? (
                    OrderDetails?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}{" "}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? checkFileExist(
                                        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                      )
                                      ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                      : `https://canada-eta.online/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className="form-inline">
                <div className="form-group">
                  <label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control input-class"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    id="download_btn"
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{" "}
                    <span>
                      <img src={rightArrow} alt="rightArrow" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                  OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {item?.us_date}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                            selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>
      </div>

      <StyledBottomBlock>
        <button
          type="button"
          className="blue-btn"
          data-toggle="modal"
          data-target="#refundModal"
          onClick={() => setShow(true)}
        >
          View Email Content{" "}
        </button>
        <button
          type="button"
          className="blue-btn"
          data-toggle="modal"
          data-target="#defencePackModal"
          onClick={() => setShowDefence(true)}
        >
          Defence Pack 1{" "}
          <span>
            <img src={rightArrow} alt="rightArrow" />
          </span>
        </button>
        <button
          type="button"
          className="blue-btn"
          data-toggle="modal"
          data-target="#defencePackModal1"
          onClick={() => setShowDefencePack(true)}
        >
          Defence Pack 2{" "}
          <span>
            <img src={rightArrow} alt="rightArrow" />
          </span>
        </button>
        <button
          type="button"
          className="blue-btn"
          data-toggle="modal"
          data-target="#transactionModal"
          onClick={() => SetShowTransaction(true)}
        >
          Enter Transaction ID
        </button>
        <MyModal
          show={show}
          close={setShow}
          size={"lg"}
          modalHeadTitle={"Email Content"}
          showFooter={true}
          primaryButtonName={"Close"}
          secondaryButtonName={"Send Whatsapp Message"}
          OrderDetails={OrderDetails}
        >
          <EmailContent />
        </MyModal>
        <MyModal
          show={showDefence}
          close={setShowDefence}
          size={"lg"}
          modalHeadTitle={"Defence Pack Content"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <DefenceContent defencePackName={null} />
        </MyModal>
        <MyModal
          show={showDefencePack}
          close={setShowDefencePack}
          size={"lg"}
          modalHeadTitle={"Defence Pack Content"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <DefenceContent defencePackName={"defencePack1"} />
        </MyModal>
        <MyModal
          show={showTransaction}
          close={SetShowTransaction}
          size={"s"}
          modalHeadTitle={"Enter Defence Pack Details:"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <TransactionModal SetShowTransaction={SetShowTransaction} />
        </MyModal>
      </StyledBottomBlock>
    </>
  );
};

export default ViewOrders;
