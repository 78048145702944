import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import {
  getCountryListing,
  updateCountryStatus,
} from "../../../redux/manageTeamSlice";

const ManageCountry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CountryList = useSelector((state) => state?.manageTeam?.countryList);
  console.log("CountryList", CountryList);
  const Count = useSelector((state) => state?.manageTeam?.count);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(getCountryListing({ page: page, perPage: limit }))
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    document.title = "Manage Country List | ETA Canada";
  }, [dispatch, navigate, page, limit]);

  const handleUpdateStatus = (data) => {
    dispatch(updateCountryStatus(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getCountryListing({ page: page, perPage: limit }));
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        toast.error(`${err.message}`, {
          className: "toast-message",
        });
      });
  };

  const columns = [
    {
      name: "S.No",
      width: "10rem",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Country",
      width: "35rem",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      width: "15rem",
      selector: (row) => {
        return tableData?.length ? (
          <span>
            <Link
              to="#"
              onClick={() =>
                handleUpdateStatus({
                  id: row?.id,
                  status:
                    row?.status === "Un-suspended"
                      ? 1
                      : row?.status === "Suspended"
                      ? 0
                      : null,
                  countryName: row?.name,
                })
              }
              style={{
                textDecoration: "none",
                border: "1px solid #e92a11",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: " #e92a11",
                margin: "5px",
              }}
            >
              {row.status === "Un-suspended"
                ? "Suspended"
                : row.status === "Suspended"
                ? "Un-suspended"
                : null}{" "}
            </Link>
          </span>
        ) : (
          " "
        );
      },
    },
  ];

  const tableData = CountryList;

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Country Listing</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              customData={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="manageCountry"
              CountryPriceListCount={Count}
              hideSelectableRows={true}
              hideSearchInput={true}
              buttonWrapper={null}
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default ManageCountry;
