import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../../pages/PageNotFound";
import ViewOrders from "../../../Components/ViewOrders/index";
import NewOrders from "../../../pages/Admin/NewOrders/index";
import PendingOrders from "../../../pages/Admin/PendingOrders/index";
import CompletedOrders from "../../../pages/Admin/CompletedOrders/index";
import ContactCustomer from "../../../pages/Admin/ContactCustomer/index";
import OrderHistory from "../../../pages/Admin/OrderHistory/index";
import DeletedOrders from "../../../pages/Admin/DeletedOrders/index";
import ChangePassword from "../../../pages/Admin/ChangePassword/index";
import Gateway from "../../../pages/Admin/GateWay/index";
import ManageTeam from "../../../pages/Admin/ManageTeam/index";
import Login from "../../../pages/AdminLogin/Login";
import RefundOrders from "../../../pages/Admin/RefundOrders";
import RejectedETA from '../../../pages/Admin/RejectedETA/RejectedETA';
import AwaitingCustomer from '../../../pages/Admin/AwaitingCustomer/index';
import AwaitingGovt from '../../../pages/Admin/AwaitingGovt/AwaitingGovt';
import ManageCountry from "../../../pages/Admin/ManageCountry";
import PriorityOrders from "../../../pages/Admin/PriorityOrders";
import ChargebackOrders from '../../../pages/Admin/ChargebackOrders/index';

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/" element={<NewOrders />} />
        <Route path="/view-order/:orderId" element={<ViewOrders />} />
        <Route path="/recent-order" element={<NewOrders />} />
        <Route path="/pending-order" element={<PendingOrders />} />
        <Route path="/completed-order" element={<CompletedOrders />} />
        <Route path="/contact-customer" element={<ContactCustomer />} />
        <Route path="/awaiting-order" element={<AwaitingCustomer />} />
        <Route path="/awaiting-govt-order" element={<AwaitingGovt />} />
        <Route path="/all-order" element={<OrderHistory />} />
        <Route path="/archive-order" element={<DeletedOrders />} />
        <Route path="/refund-order" element={<RefundOrders />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/setting" element={<Gateway />} />
        <Route path="/rejected-orders" element={<RejectedETA />} />
        <Route path="/chargeback-orders" element={<ChargebackOrders />} />

        <Route
          path="/manage-country"
          element={<ManageCountry />}
        />
        <Route path="/manage-team" element={<ManageTeam />} />
        <Route path="/priority-order" element={<PriorityOrders />} />
        <Route path="/manage-team/:teamMemberId" element={<ManageTeam />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
