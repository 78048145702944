export const decryptVal = (string) => {
  try {
    string = atob(string);
    if (!isNaN(string)) {
      return string / 2;
    }
    return string;
  } catch (err) {
    return 0;
  }
};

export const encryptVal = (string) => {
  try {
    if (!isNaN(string)) {
      string = parseInt(string) + parseInt(string);
    }
    string = btoa(string);
    return string.replace(/=+$/, "");
  } catch (err) {
    return 0;
  }
};

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export const capitalizeFirstLowercaseRest = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const countriesList = [
  "Afghanistan",
  "Algeria",
  "Angola",
  "Bangladesh",
  "Benin",
  "Bhutan",
  "Botswana",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "China",
  "Comoros",
  "Congo",
  "Djibouti",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Ethiopia",
  "Fiji",
  "Gabon",
  "Gambia",
  "Ghana",
  "Guinea",
  "Guinea-Bissau",
  "India",
  "Iraq",
  "Kenya",
  "Kuwait",
  "Lesotho",
  "Malawi",
  "Mali",
  "Mauritania",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Niger",
  "Nigeria",
  "Pakistan",
  "Philippines",
  "Rwanda",
  "Senegal",
  "Solomon Islands",
  "Somalia",
  "Suriname",
  "Swaziland",
  "Taiwan",
  "Province of China",
  "Tanzania, United Republic of",
  "Togo",
  "Uganda",
  "United Arab Emirates",
  "Vanuatu",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const residentCountryList = [
  "Austria",
  "Belgium",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Norway",
  "Estonia",
  "Portugal",
  "Schengen",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "United States",
  "United Kingdom",
];

export async function fileExists(url) {
  let status = false;
  return await fetch(url, { method: "HEAD" })
    .then((response) => {
      // console.log(response, "fileExistresponse");
      status = typeof response.ok !== "undefined" ? true : false;
      return status;
    })
    .catch((error) => {
      return false;
    });
}

export const yearList = [
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
  "1979",
  "1978",
  "1977",
  "1976",
  "1975",
  "1974",
  "1973",
  "1972",
  "1971",
  "1970",
  "1969",
  "1968",
  "1967",
  "1966",
  "1965",
  "1964",
  "1963",
  "1962",
  "1961",
  "1960",
  "1959",
  "1958",
  "1957",
  "1956",
  "1955",
  "1954",
  "1953",
  "1952",
  "1951",
  "1950",
  "1949",
  "1948",
  "1947",
  "1946",
  "1945",
  "1944",
  "1943",
  "1942",
  "1941",
  "1940",
  "1939",
  "1938",
  "1937",
  "1936",
  "1935",
  "1934",
  "1933",
  "1932",
  "1931",
  "1930",
  "1929",
  "1928",
  "1927",
  "1926",
  "1925",
  "1924",
  "1923",
];

export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dayList = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const countryCode = [
  "Algeria (+213)",
  "Andorra (+376)",
  "Angola (+244)",
  "Anguilla (+1264)",
  "Antigua & Barbuda (+1268)",
  "Argentina (+54)",
  "Armenia (+374)"
   ]