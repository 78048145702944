import React, { useEffect, useState } from "react";
import {
  StyledContainer,
  StyledPageTitle,
  StyledOrderBlock,
  StyledBottomBlock,
} from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import rightArrow from "../../../assests/images/right-arrow.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../redux/orderSlice";
import { toast } from "react-toastify";
import {
  assignToTeamMember,
  getTeamMemberList,
} from "../../../redux/manageTeamSlice";
import { useFormik } from "formik";
import * as yup from "yup";

const initialValues = {
  teamMember: "",
};

const teamMemberSchema = yup.object({
  teamMember: yup.string().required("Please select gateway"),
});

const NewOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const NewOrdersList = useSelector((state) => state?.order?.ordersList);
  const TeamMembersList = useSelector(
    (state) => state?.manageTeam?.teamMemberList
  );
  const SelectedData = useSelector((state) => state?.order?.selectedData);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (token) {
      dispatch(
        getOrdersList({
          orderName: "neworder",
          page: page,
          perPage: limit,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.status === 401) {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
            localStorage.removeItem("user");
            navigate("/");
          }
        });
      dispatch(getTeamMemberList());
    }
    document.title = "New Orders | ETA Canada";
  }, [dispatch, navigate, page, limit, token]);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: teamMemberSchema,
    onSubmit: (values) => {
      let data = {
        orderId: SelectedData,
        tmId: values.teamMember,
      };
      if (SelectedData.length !== 0) {
        dispatch(assignToTeamMember(data))
          .unwrap()
          .then((res) => {
            if (res.status === 1) {
              dispatch(
                getOrdersList({
                  orderName: "neworder",
                  page: page,
                  perPage: limit,
                })
              );
              toast.success(`${res.message}`, {
                className: "toast-message",
              });
            } else {
              toast.error(`${res.message}`, {
                className: "toast-message",
              });
            }
          });
      }
    },
  });

  const tableData = NewOrdersList;

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>New Order</h1>
      </StyledPageTitle>
      <form onSubmit={handleSubmit}>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="neworder"
            />
          </div>
        </StyledOrderBlock>
        <StyledOrderBlock>
          <h3>Team Member</h3>
          <select
            className="form-control select-box"
            name="teamMember"
            value={values.teamMember}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option>Select Team Member</option>
            {TeamMembersList &&
              TeamMembersList?.map((item, i) => {
                return (
                  <option value={item?.id} key={i}>
                    {item?.name}
                  </option>
                );
              })}
          </select>
          <p>{errors.teamMember}</p>
        </StyledOrderBlock>
        <StyledBottomBlock>
          <div className="row">
            <div className="col-sm-6">
              <button
                type="submit"
                name="submit"
                value="Assign"
                className="btn blue-btn"
              >
                Assign To{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        </StyledBottomBlock>
      </form>
    </StyledContainer>
  );
};

export default NewOrders;
