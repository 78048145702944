import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { SearchField, ButtonWrapper, StyledDataTable } from "./style";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";
import rightArrow from "../../assests/images/right-arrow.png";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getAllFolderCount,
  deleteOrdersData,
  sendRefundMail,
  handleChange,
  getOrdersList,
} from "../../redux/orderSlice";
import Pagination from "../customPagination";
import { encryptVal } from "../../utility/utility";
const ReactDataTable = ({
  data,
  hideButtonWrapper,
  buttonWrapper,
  customColumns,
  customData,
  customOrdersData,
  hideSelectableRows,
  orderName,
  setPage,
  setLimit,
  selectableRowDisabled,
  CountryPriceListCount,
  hideSearchInput,
}) => {
  const isLoading = useSelector((state) => state?.order?.loading);
  hideButtonWrapper =
    typeof hideButtonWrapper !== "undefined" ? hideButtonWrapper : false;
  buttonWrapper =
    typeof buttonWrapper === "undefined" ? (
      <ButtonWrapper>
        <div className="row">
          <div className="col-sm-6">
            <button
              type="button"
              name="submit"
              value="Delete"
              className="btn blue-btn"
              onClick={() => setShow(true)}
            >
              Delete Order{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
          </div>
        </div>
      </ButtonWrapper>
    ) : typeof buttonWrapper === "string" && buttonWrapper === "refund" ? (
      <ButtonWrapper>
        <div className="row">
          <div className="col-sm-6">
            <button
              type="button"
              name="submit"
              value="Delete"
              className="btn blue-btn"
              onClick={() => setShow(true)}
            >
              Delete Order{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>{" "}
            <button
              type="button"
              name="submit"
              value="invoice"
              className="btn blue-btn"
              onClick={() => handleRefundMail()}
              disabled={isLoading}
            >
              Refunded it{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
          </div>
        </div>
      </ButtonWrapper>
    ) : (
      buttonWrapper
    );

  let filteredArray = [];
  let columns;
  let Data = [];
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState();
  const [show, setShow] = useState(false);
  const SelectedData = useSelector((state) => state?.order?.selectedData);
  const count = useSelector((state) => state?.order?.count);
  // const [searchText, setSearchText] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [rowLimit, setRowLimit] = useState(10);
  const [filterText, setFilterText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData([orderId]);
  };
  console.log(selectedData);
  let [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (SelectedData && SelectedData?.length !== 0) {
      setSelectedData(SelectedData);
    }
  }, [SelectedData]);

  useEffect(() => {
    setCurrentPage(pageNum);
    orderName?.includes("awaitingorder")
      ? setTotalRows(count?.awaitingOrdersCount)
      : orderName?.includes("awaitinggovt")
      ? setTotalRows(count?.awaitingGovtCount)
      : orderName?.includes("rejectedorder")
      ? setTotalRows(count?.rejectedOrdersCount)
      : orderName?.includes("completed")
      ? setTotalRows(count?.completedOrdersCount)
      : orderName?.includes("delete")
      ? setTotalRows(count?.deletedOrdersCount)
      : orderName?.includes("contactcustomer")
      ? setTotalRows(count?.contactCustomerOrdersCount)
      : orderName?.includes("new")
      ? setTotalRows(count?.newOrdersCount)
      : orderName?.includes("all")
      ? setTotalRows(count?.allOrdersCount)
      : orderName?.includes("pending")
      ? setTotalRows(count?.pendingOrdersCount)
      : orderName?.includes("refund")
      ? setTotalRows(count?.refundOrdersCount)
      : orderName?.includes("savecontinue")
      ? setTotalRows(count?.saveContinueOrdersCount)
      : orderName?.includes("manageCountry")
      ? setTotalRows(CountryPriceListCount)
      : setTotalRows(0);
  }, [pageNum, count, orderName]);

  console.log("totalRows", totalRows);

  const handlePageChange = (page) => {
    setPage(page);
    setPageNum(page);
  };

  const handleRowsChange = (limit) => {
    if (limit === "All") {
      setLimit(totalRows);
      setRowLimit(totalRows);
    } else {
      setLimit(limit);
      setRowLimit(limit);
    }
  };

  const handleRefundMail = () => {
    if (
      selectedData !== null &&
      selectedData?.length !== 0 &&
      typeof selectedData !== "undefined"
    ) {
      // console.log("selectedData",selectedData);
      dispatch(sendRefundMail(selectedData))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(
              getOrdersList({
                orderName: "refundorder",
                page: pageNum,
                perPage: rowLimit,
              })
            );
          } else {
            if (res.status === 0) {
              toast.error(`${res.message}`, {
                className: "toast-message",
              });
            }
          }
        });
    }
  };
  console.log(SelectedData);
  const deleteOrder = () => {
    let data = {
      orderId: selectedData,
      // permanentDeletedFlag: orderName === "deletedorder" ? true : false,
      updatingStatus: "Deleted",
    };
    console.log("data", data);
    dispatch(deleteOrdersData(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(
            getOrdersList({
              orderName: orderName,
              page: pageNum,
              perPage: rowLimit,
            })
          );
          dispatch(getAllFolderCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };

  if (
    data &&
    !customData &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0
  ) {
    Data = data?.map((item) => {
      return {
        id: item?.order_id,
        name: `${item?.first_name} ${item?.surname}`,
        email: item?.email,
        telephone: item?.telephone_number,
        // date: moment(item?.create_ts).format("MM-DD-YYYY"),
        date: item?.create_ts,
        status: item?.process_status,
        refundDate: item?.refund_date ? item?.refund_date : "-",
        // status: item?.order_change_status
        //   ? titleCase(item?.order_change_status)
        //   : item?.order_change_status === null ||
        //     item?.order_change_status === ""
        //   ? "New Orders"
        //   : "-",
        assignto: item?.assign_to ? item?.assign_to : "-",
      };
    });
  } else if (typeof customData !== "undefined" && customData?.length !== 0) {
    Data = customData?.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        email: item?.email,
        password: item?.password,
        type: item?.type ? item?.type : "-",
        country: item?.countryname,
        currency: "USD",
        price: item?.USD,
        status:
          item?.status === 0
            ? "Suspended"
            : item?.status === 1
            ? "Un-suspended"
            : null,
      };
    });
  } else {
    Data = customOrdersData?.map((item) => {
      return {
        id: item?.order_id,
        name: item?.First_Name,
        email: item?.Email,
        date: item?.create_ts,
        processing_date: item?.processing_date,
        month: item?.process_month,
        status: item?.process_status,
        assignto: item?.assign_to ? item?.assign_to : "-",
        assignto: item?.assign_to ? item?.assign_to : "-",
      };
    });
  }

  if (filterText !== "") {
    console.log("Data", Data);
    filteredArray = Data?.filter(
      (item) =>
        (item?.id).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.name).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.email).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.telephone).toLowerCase().includes(filterText.toLocaleLowerCase())
    ).map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        email: item?.email,
        telephone: item?.telephone,
        date: item?.date,
        status: item?.status,
        refundDate: item?.refund_date ? item?.refund_date : "-",
        assignto: item?.assignto ? item?.assignto : "-",
      };
    });
    Data = filteredArray;
  }

  if (!customColumns) {
    columns = [
      {
        name: "Order Id",
        width: "8rem",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        width: "10rem",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Email",
        width: "13rem",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Telephone",
        width: "8rem",
        selector: (row) => row.telephone,
        sortable: true,
      },
      {
        name: "Date & Time",
        width: "10rem",
        selector: (row) =>
          row.date
            ? moment(new Date(row.date)).format("MM-DD-YYYY hh:mm:ss")
            : "",
        sortable: true,
      },
      {
        name: "Assign To",
        width: "8rem",
        selector: (row) => row.assignto,
        sortable: true,
      },
      {
        name: "Status",
        width: "8rem",
        selector: (row) => row.status,
        sortable: true,
      },
      {
        name: "Action",
        width: "14rem",
        selector: (row) =>
          Data?.length ? (
            <span>
              <Link
                to={`/admin/view-order/${encryptVal(row?.id)}`}
                style={{
                  border: "1px solid #18f",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: "#111",
                  margin: "5px",
                  textDecoration: "none",
                }}
                className="blue-border"
              >
                View
              </Link>{" "}
              <Link
                to="#"
                onClick={() => handleShow({ id: row?.id, status: row?.status })}
                style={{
                  textDecoration: "none",
                  border: "1px solid #e92a11",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: " #e92a11",
                  margin: "5px",
                }}
              >
                Delete
              </Link>
            </span>
          ) : (
            " "
          ),
      },
    ];

    if (typeof orderName !== "undefined" && orderName !== null) {
      if (orderName === "contactcustomerorder") {
        let FilterColumn = columns.filter((item) => item.name !== "Telephone");
        columns = FilterColumn;
      }
    }
  } else {
    columns = customColumns;
  }

  const CustomMaterialPagination = () => {
    totalRows = typeof totalRows === "undefined" ? 0 : totalRows;
    return (
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalRows}
        pageSize={rowLimit}
        onPageChange={(page) => handlePageChange(page)}
        paginationRowsPerPageOptions={["10", "25", "50", "100", "500", "All"]}
        handleRowsChange={handleRowsChange}
      />
    );
  };

  return (
    <>
      <Modal
        show={show}
        close={handleClose}
        size={"s"}
        confirmAlert={true}
        noEvent={() => handleClose()}
        yesEvent={() => deleteOrder()}
      ></Modal>
      {hideSearchInput ? null : (
        <SearchField>
          <input
            type="text"
            id="search_field"
            className="search-bar form-control"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            name="search_field"
            placeholder="Search"
          />
        </SearchField>
      )}
      <StyledDataTable>
        <DataTable
          className="uspassport-custom-tbl"
          selectableRows={
            hideSelectableRows ? false : Data?.length ? true : false
          }
          columns={columns}
          data={Data?.length ? Data : [{}]}
          progressPending={isLoading}
          fixedHeader={true}
          fixedHeaderScrollHeight="550px"
          pagination={Data?.length && !customOrdersData ? true : false}
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          paginationComponentOptions={{ selectAllRowsItem: true }}
          noDataComponent="No records found"
          onSelectedRowsChange={(e) => dispatch(handleChange(e))}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsChange}
          selectableRowDisabled={selectableRowDisabled}
          paginationComponent={CustomMaterialPagination}
        />
      </StyledDataTable>
      {!Data?.length && isLoading === false && (
        <p style={{ textAlign: "center", color: "red" }}>
          {"No data available in table"}
        </p>
      )}
      {!hideButtonWrapper && buttonWrapper}
    </>
  );
};

export default ReactDataTable;
