import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLogo from "../../../assests/images/logo.svg";
import OrderConfirmation from "../../../assests/images/orderconfirmation.jpg";
import PaymentConfirmation from "../../../assests/images/paymentconfirmation.jpg";
import CompletedOrder from "../../../assests/images/completeorder.jpg";
import {
  DefenceContainer,
  StyledImage,
  StyledOrderBlock,
  StyledDetail,
  StyledBlock,
} from "./style";
import { Link } from "react-router-dom";
import moment from "moment";
import rightArrow from "../../../assests/images/right-arrow.png";
import { GetDefencePackPdf } from "../../../redux/orderSlice";
import { FaDownload } from "react-icons/fa";
import { fileExists } from "../../../utility/utility";
import { date } from "yup";

const DefenceContent = ({ defencePackName }) => {
  const dispatch = useDispatch();
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);

  const handleDefencePack = (e) => {
    let data = {
      orderId: OrderDetails?.order_id,
      defencePackType: Number(e.target.id),
    };
    dispatch(GetDefencePackPdf(data))
      .unwrap()
      .then(async (res) => {
        console.log("res",res);
        if (res !== null) {
          const fileURL = URL.createObjectURL(res);
          console.log("fileURL",fileURL);
          window.open(fileURL);
        }
      });
  };

  const checkFileExist = (url) => {
    if (typeof url !== "undefined" && url !== null && url !== "") {
      return fileExists(url).then((response) => {
        return response;
      });
    }
  };
  const today = new date();
  return (
    <DefenceContainer>
      <StyledImage>
        <img
          src="https://canada-eta.online/images/logo.jpg"
          className="admin-logo"
        />
      </StyledImage>
      <p>Electric Doc Services LLC </p>
      <p>1800 Pembrook Dr.</p>
      <p>Suite 300</p>
      <p>Orlando</p>
      <p>FL, 32810</p>
      <p>Tel: +1 (407) 305-3310</p>
      <p className="email">Inquiries@canada-eta.online</p>
      <p>EIN: 85-1546748</p>
      <StyledDetail>{moment(today).format("MMMM DD, YYYY")}</StyledDetail>
      <br />
      <StyledDetail>{OrderDetails?.transaction_id} </StyledDetail>
      <br />
      <StyledDetail>Dear {OrderDetails?.merchant_name} </StyledDetail>
      <br />
      <ol>
        The customer was provided with the service that we advertise, and the
        customer received the ETA confirmation which we paid on their behalf.
        Please find enclosed all the details regarding our service. This is the
        process through in which a customer must proceed to place an order with
        us:
      </ol>
      <br />
      <li
      //  style="display: block;margin-left: auto;margin-right: auto;width: 90%;"
      >
        <b>(1.)</b> Prior to submitting the online application for the eTA, a
        customer who visits our website and wants to learn more about our
        services before proceeding can view the costs of our services as well as
        the additional benefits we provide for our customers.
        <b>(Page 2)</b>
        <br />
        <br />
        In fact, we even have a disclaimer on our site which states that{" "}
        <b>
          (Our service is not connected to or affiliated with the Canadian
          Government),
        </b>{" "}
        so the customer is aware of the fact that we are a third-party service
        provider.<b>(Page 2)</b>
      </li>
      <br />
      <li
      // style="display: block;margin-left: auto;margin-right: auto;width: 90%;"
      >
        <b>(2.)</b> When the customer has completed and paid for the online
        application, we are then able to see the{" "}
        <b>Order ID, Date and Time, IP address, Time Zone location,</b> as well
        as the <b>signature</b>. With all of this information, we are then able
        to confirm how many times the application was downloaded.
        <b>( Page 3,4,5 and 6 )</b>
      </li>
      <br />
      <li>
        Any information like <b>IP addresses, emails, phone numbers,</b> and{" "}
        <b>addresses</b> provided by customers are passed on to Payees, if there
        is suspicion of fraud, the payment would be declined.
      </li>
      <br />
      <StyledBlock
      // style="border:black; border-width:3px; border-style:solid; font-weight: bold;"
      >
        NEITHER A REFUND NOR A COMPLAINT HAS BEEN SUBMITTED BY THIS CUSTOMER, SO
        THIS FIRST CHARGEBACK SHOULD BE DENIED ON THE BASIS THAT NO REFUND HAS
        BEEN REQUESTED.
      </StyledBlock>
      <br />
      <span>Fees</span> (Before apply page)
      <br />
      Fee page with links to our additional benefits and Government site.
      <p></p>
      <br />
      <img src="https://canada-eta.online/admin/assets/images/fee_page.png" />
      <br /> <br />
      <b>
        <span>Disclaimer</span>
      </b>{" "}
      (Before apply page)
      <br />
      Homepage with various contact options links and disclaimer.
      <p></p>
      <br />
      <img src="https://canada-eta.online/admin/assets/images/disclaimer1.png" />
      <br /> <br />
      <b>
        <span>Payment Confirmation</span>
      </b>{" "}
      (After completion)
      <br />
      Payment confirmation page.
      <p></p>
      <br />
      <img src="https://canada-eta.online/admin/assets/images/thankyou_page.png" />
      <br /> <br />
      <b>
        <span>Order Confirmation</span>
      </b>
      <br />
      In the confirmation email that is sent to the customer after the order has
      been paid for, there is a link to our terms and conditions. At this stage
      the customer is still able to contact us if they have any questions,
      concerns or require a refund.
      <br />
      It is disappointing that the customer has not contacted us despite
      receiving many opportunities to do so. A copy of our terms can be{" "}
      <a href="https://canada-eta.online/terms.php">
        found here: https://canada-eta.online/ terms.php{" "}
      </a>
      <p></p>
      <br />
      <img src="https://canada-eta.online/admin/assets/images/order_confirmation_mail.png" />
      <br /> <br />
      <b>
        <span>Completed Order Confirmation</span>
      </b>
      <br />
      Once the eTA application has been approved, we email the customer a link
      to our <b>portal</b>
      where they can download their approved eTA and be authorized to travel to
      Canada by air.
      <p></p>
      <br />
      <img src="https://canada-eta.online/admin/assets/images/complete_order_confirmation_mail.png" />
      <br /> <br />
      <b>
        <span>Completed Order</span>
      </b>
      <br />
      In addition to emailing the customer an <b>eTA</b> approval in a{" "}
      <b>PDF</b> format, we track how many times it has been downloaded.
      <br />
      As the customer accepts our terms and conditions, we can view the digital
      signature the customer has entered. Sometimes the signature is not perfect
      due to the device being used, but we can verify that we have the{" "}
      <b>date, time, IP address,</b> and <b>location</b> of the customer.
      <p></p>
      <br />
      <div
      // style="border:black; padding:15px; border-width:3px; border-style:solid;"
      >
        <StyledBlock>
          {defencePackName !== "defencePack1" ? (
            <StyledOrderBlock>
              <h2>Download History</h2>
              <div className="table-block table-responsive">
                <table width="100%">
                  <thead>
                    <tr>
                      <th width="5%">#</th>
                      <th width="20%">Order ID</th>
                      <th width="25%">Date & Time</th>
                      <th width="38%">Downloaded File</th>
                      <th width="21%">IP Address</th>
                      <th width="22%">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.downloadHistory ? (
                      OrderDetails?.downloadHistory?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.order_id}</td>
                            <td>
                              {moment(item?.create_ts).format(
                                "MM-DD-YYYY h:mm:ss"
                              )}{" "}
                              EST
                            </td>
                            <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? checkFileExist(
                                        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                      )
                                      ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                      : `https://canada-eta.online/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File{" "}
                                <span>
                                  <FaDownload className="download-icons" />
                                </span>
                              </a>
                              )}
                            </td>
                            <td>{item?.ip}</td>
                            <td>{item?.timezone}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" align="center">
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          ) : (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "30%" }}>Order ID</th>
                      <th style={{ width: "40%" }}>Download</th>
                      <th style={{ width: "25%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                  {OrderDetails?.mailHistory?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="font-normal">{item?.order_id}</td>
                        <td>
                          {OrderDetails?.uploadDoc?.length !== 0 && (
                            <a
                              href={
                                OrderDetails?.uploadDoc !== undefined
                                  ? checkFileExist(
                                      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                    )
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : `https://canada-eta.online/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                  : null
                              }
                              className="blue-btn"
                              target="_blank"
                              // download
                              rel="noreferrer"
                            >
                              Download File
                            </a>
                          )}
                        </td>
                        <td>
                          {moment(item?.create_ts).format(
                            "MM-DD-YYYY h:mm:ss"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}
        </StyledBlock>
      </div>
      <br />
      <StyledBlock>
        <img
          src="https://canada-eta.online/signature/uploads/ETA-200020362signature.png"
          width="85%"
          height="70%"
        />
      </StyledBlock>
      <br />
      <br />
      <b>
        <span>
          Pictured below<span></span>
        </span>
      </b>{" "}
      This is the approved <b>eTA</b> in <b>PDF</b> format, which the customer
      downloaded from our portal.
      <p></p>
      <br />
      <div
      // style="border:black; padding:15px; border-width:3px; border-style:solid;"
      >
        <embed
          // style="border:black; border-width:3px; border-style:solid;"
          width="500"
          height="400"
          src={
            OrderDetails?.length !== 0
              ? checkFileExist(
                  `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}}`
                )
                ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                : `https://canada-eta.online/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
              : null
          }
        />
      </div>
      {defencePackName !== "defencePack1" ? (
        <button type="button" onClick={handleDefencePack} id="1">
          Print Defence Pack 1{" "}
          <span>
            <img src={rightArrow} alt="rightArrow" />
          </span>
        </button>
      ) : (
        <button type="button" onClick={handleDefencePack} id="2">
          Print Defence Pack 2{" "}
          <span>
            <img src={rightArrow} alt="rightArrow" />
          </span>
        </button>
      )}
    </DefenceContainer>
  );
};

export default DefenceContent;
