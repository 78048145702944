import ApiService from "./ApiService";

export default class manageTeamService {
  static getTeamMemberList = () =>
    ApiService.get("/admin/get-team-member-list");

  static addTeamMember = (data) =>
    ApiService.post("/admin/add-team-member", data);

  static deleteTeamMember = (data) =>
    ApiService.put("/admin/delete-team-member", data);

  static getTeamMemberById = (teamMemberId) =>
    ApiService.get(`/admin/team-member-info/${teamMemberId}`);

  static updateTeamMember = (updateData) =>
    ApiService.post("/admin/update-team-member", updateData);

  static assignToTeamMember = (data) =>
    ApiService.post("/admin/assign-order-to-team-member", data);

  static getTeamMemberProcessOrderIdError = (data) =>
    ApiService.post("/admin/get-team-member-error-order-list", data);

  static getCountryListing = (data) =>
    ApiService.get(
      `/admin/get-country-list?page=${data.page}&limit=${data.perPage}&delay=1`
    );

  static updateCountryStatus = (data) =>
    ApiService.post("/admin/update-country-status", data);
}
