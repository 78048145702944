import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import AdminLogo from "../../../assests/img/CanadaLogo.jpg";
import { encryptVal } from "../../../utility/utility";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p>
        <b>{`Dear Customer: ${OrderDetails?.first_name} ${OrderDetails?.surname}`}</b>
      </p>
      <p>
        We would like to inform you that your application has been successfully
        approved.
      </p>
      <p>
        <b>An eTA is automatically linked to your passport.</b>
      </p>
      <p>To download your eTA confirmation</p>
      <p>Please click on the link below</p>
      <h6>
        Username :
        <a href={`mailto:${OrderDetails?.email}`}>{OrderDetails?.email}</a>
      </h6>
      <br />
      <p className="flex-class">
        <b>Order ID : </b>
        {OrderDetails?.order_id}
      </p>
      <br />
      <p>
        <Link
          to={`https://canada-eta.online/track.php?order_id=${encryptVal(OrderDetails?.order_id)}`}
          target="_blank"
          // style="background:#007bff; border:solid 2px #007bff; color:#fff; padding:10px 25px;"
          className="click-btn"
        >
          Click Here
        </Link>
      </p>
      <br />
      Your eTA has been registered and it is available electronically for review
      by your airline at check-in and by the Canada Immigration Authorities on
      your arrival in Canada. You do not need a label in your passport. Your eTA
      has been recorded with the data and conditions detailed in the PDF
      document which is available to download.
      <p></p>
      <p>
        <ul>
          {" "}
          <b>eTA Validity:</b>
        </ul>
      </p>
      <li>
        {" "}
        The eTA is valid until the Expiration date noted in the document. This
        is the last day that you have the authority to enter Canada.
      </li>
      <li>
        {" "}
        This expiry date will either be 5 years from the issue date or until the
        expiry of your passport.
      </li>
      <li>
        The eTA is a multiple entry authorization allowing a maximum of 6 months
        per visit.
      </li>
      <li>
        You are permitted to stay beyond your eTA's validity date, but not
        beyond the 6-month maximum per trip.
      </li>
      <li>
        If you obtain a new passport for any reason, you will need to re-apply
        for a new Canadian Travel Authorization (eTA).
      </li>
      <br />
      <br />
      <p>
        It is recommended, but not required, that you take a printed or digital
        copy of your eTA with you to Canada.
      </p>
      <p>
        Please make sure you check the official requirements and health
        regulations before traveling to your destination as travel restrictions
        may be applied in some cases.
      </p>
      <p>
        Please contact us with any questions you may have regarding your Travel
        Authorization (eTA).
      </p>
      <p>
        Should you have any inquiries, please contact us through our
        <Link target="_blank" to="https://canada-eta.online/contact.php">
          {" "}
          contact form{" "}
        </Link>
        or via email at{" "}
        <a href="mailto:inquiries@canada-eta.online">
          inquiries@canada-eta.online
        </a>{" "}
        indicating your order ID.
      </p>
      <p className="flex-class">
        <b>Customer Service Number:</b> +1 (407) 305 - 3310
      </p>
      <p>
        {" "}
        <b>Important notice:</b>
      </p>
      <p>
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at{" "}
        <a href="mailto:inquiries@canada-eta.online">
          {" "}
          inquiries@canada-eta.online
        </a>
        , where we will be happy to assist you or alternatively click on one of
        the links below for more information.
      </p>
      <p>
        You can access the terms and conditions you agreed to by clicking here
        <Link target="_blank" to="https://canada-eta.online/terms.php">
          {" "}
          click here
        </Link>
      </p>
      <p>Thank you for using our services</p>
      <p> Regards,</p>
      <p>Processing Department</p>
      <p>
        <Link target="_blank" to="https://canada-eta.online/terms.php">
          Terms
        </Link>{" "}
        |{" "}
        <Link target="_blank" to="https://canada-eta.online/privacy.php">
          Privacy
        </Link>{" "}
        |{" "}
        <Link target="_blank" to="https://canada-eta.online/contact.php">
          Contact
        </Link>{" "}
        |{" "}
        <Link target="_blank" to="https://canada-eta.online/refund.php">
          Refund
        </Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
