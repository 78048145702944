import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../redux/orderSlice";
import { toast } from "react-toastify";
import moment from "moment";

const ContactCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ContactCustomersList = useSelector((state) => state?.order?.ordersList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getOrdersList({
        orderName: "contactcustomerorder",
        page: page,
        perPage: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    document.title = "Contact Customer | ETA Canada";
  }, [dispatch, navigate, page, limit]);

  const tableData = ContactCustomersList;

  const columns = [
    {
      name: "Order Id",
      width: "10rem",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      width: "15rem",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      width: "15rem",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Date & Time",
      width: "10rem",
      selector: (row) =>
        row.date ? moment(new Date(row.date)).format("YYYY-MM-DD") : "",
      sortable: true,
    },
    {
      name: "Assign To",
      width: "10rem",
      selector: (row) => row.assignto,
      sortable: true,
    },
    {
      name: "Status",
      width: "15rem",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      width: "20rem",
      selector: (row) =>
        tableData?.length ? (
          <span>
            <Link
              to={`/admin/view-order/${row?.id}`}
              style={{
                border: "1px solid #18f",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: "#111",
                margin: "5px",
                textDecoration: "none",
              }}
              className="blue-border"
            >
              View
            </Link>{" "}
          </span>
        ) : (
          " "
        ),
    },
  ];

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Contact Customer</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="contactcustomerorder"
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default ContactCustomer;
